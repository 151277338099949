import connector from '../connector';
import { ApiResponse } from '../../@types/global';
import { User } from '../../@types/Entity/User';
import { UserType } from '../../pages/users/userManagementPage';

export function getSchoolTeachers() {
  return connector.get<ApiResponse<User[]>>('/api/school/teachers').then((response) => response.data);
}

export function getSchoolTeachersWithoutMentee() {
  return connector.get<ApiResponse<User[]>>('/api/school/teachers?onlyWithoutMentee').then((response) => response.data);
}

export function getSchoolActivities({
  order,
  filter,
  show,
  page,
  tag,
  academic_year_ids,
  development_period_ids,
  start_date,
  end_date,
  users,
  groups,
  activity_types,
}: {
  order: string;
  filter: string;
  show: string;
  page: number;
  tag?: string;
  academic_year_ids: Array<string>;
  development_period_ids: Array<string>;
  start_date: string;
  end_date: string;
  users: Array<string>;
  groups: Array<string>;
  activity_types: Array<string>;
}) {
  return connector.get<ApiResponse<{ users: UserType[] }>>('/api/school/activities', {
    params: {
      order,
      filter,
      show,
      page,
      tag,
      academic_year_ids,
      development_period_ids,
      start_date,
      end_date,
      users,
      groups,
      activity_types
    },
  });
}

export function getSchoolAggregatedMetrics({ academic_year_ids }: { academic_year_ids: Array<string> }) {
  return connector.get('api/school/aggregatedmetrics', { params: { academic_year_ids } });
}

export function downloadSchoolActivitiesCsv({
  order,
  filter,
  show,
  page,
  tag,
  academic_year_ids,
  development_period_ids,
  start_date,
  end_date,
  users,
  groups,
  activity_types,
  name,
}: {
  order: string;
  filter: string;
  show: string;
  page: number;
  tag?: string;
  academic_year_ids: Array<string>;
  development_period_ids: Array<string>;
  start_date: string;
  end_date: string;
  users: Array<string>;
  groups: Array<string>;
  activity_types: Array<string>;
  name: string;
}) {
  return connector
    .get(`api/school/export/activities`, {
      responseType: 'blob', params: {
        order,
        filter,
        show,
        page,
        tag,
        academic_year_ids,
        development_period_ids,
        start_date,
        end_date,
        users,
        groups,
        activity_types,
      }
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', name);
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
}
export function downloadSchoolCertificate() {
  return connector
    .get('api/school/downloadsend/certificate', { responseType: 'blob' })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'School Certificate.pdf');
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
}

export function updateSchoolSize(school_size: string) {
  return connector.put('/api/school/schoolSize',  { school_size });
}

export function getOnboardingTracking() {
  return connector.get('/api/onboarding/tracking');
}

export function updateOnboardingTracking(onboarding_tracking_type: string) {
  return connector.put('/api/onboarding/tracking/update', { onboarding_tracking_type } );
}

export function getDashboardCheckList() {
  return connector.get('/api/dashboard/checklist');
}

// export function updateDashboardCheckList(dashboard_checklist_keyword: string) {
//   return connector.put('/api/dashboard/checklist', { dashboard_checklist_keyword } );
// }

export function updateDashboardCheckList(id: string) {
  return connector.put(`/api/dashboard/checklist/${id}` );
}
